import { render, staticRenderFns } from "./SectionSpecsV1.vue?vue&type=template&id=c77b3608&"
import script from "./SectionSpecsV1.vue?vue&type=script&lang=js&"
export * from "./SectionSpecsV1.vue?vue&type=script&lang=js&"
import style0 from "./SectionSpecsV1.vue?vue&type=style&index=0&id=c77b3608&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsCommonAtomTitle: require('/app/components/atoms/common/AtomTitle.vue').default,AtomsCommonAtomImageTitle: require('/app/components/atoms/common/AtomImageTitle.vue').default,OrganismsProjectOrganismTechnicalSpecs: require('/app/components/organisms/project/OrganismTechnicalSpecs.vue').default})
